<template>
  <!-- <v-layout> -->
  <!-- <v-list nav> -->
  <!-- <v-list-item
      v-for="(item, index) in items"
      :key="index"
    >
      <button
        :theme="item.theme"
        @click="themeClick(item.theme)"
        class="btn"
      >
        {{ item.title }}
      </button>
    </v-list-item> -->
    
  <ul class="text-center min-h-full bg-base-100 text-base-content">
    <!-- <h1>Customize Theme</h1>
    <br> -->
    <v-list-item
      v-for="(item, index) in daisies"
      :key="index"
    >
      <button
        class="btn bg-primary glass w-full text-primary-content"
        :data-theme="item.theme"
        @click="daisyClick(item.theme)"
      >
        {{ item.title }}
      </button>
    </v-list-item>
  </ul>
  <!-- </v-list> -->
  <!-- </v-layout> -->
</template> 

<script setup>
  // import { useTheme } from 'vuetify'
  import { useUserDataStore } from '~~/stores/userData';

  // const items = ref([
  //   { title: 'Night Mode', theme: 'myPurpleTheme', dark:true  },
  //   { title: 'Light Mode', theme: 'myPurpleTheme', dark:false },
  //   { title: 'Neutral Mode', theme: 'myNeutralTheme' }
  // ])
  const daisies = ref([

    { title: 'cupcake', theme: 'cupcake'  }, //5
    { title: 'valentine', theme: 'valentine' }, //5
    { title: 'Warmth', theme: 'customTheme' }, //5
    // { title: 'winter', theme:'winter'},
    // { title: 'luxury', theme: 'luxury' },
    // { title: 'light', theme: 'light' },
    // { title: 'dark', theme: 'dark' },
    { title: 'bumblebee', theme: 'bumblebee' },
    // { title: 'emerald', theme: 'emerald' }, 
    // { title: 'corporate', theme: 'corporate' },
    // { title: 'synthwave', theme: 'synthwave' },
    // { title: 'retro', theme: 'retro' }, 
    // { title: 'halloween', theme: 'halloween' },
    // { title: 'garden', theme: 'garden' },
    { title: 'forest', theme: 'forest' },
    // { title: 'aqua', theme: 'aqua' },
    // { title: 'lofi', theme: 'lofi' },
    // { title: 'pastel', theme: 'pastel' },
    // { title: 'fantasy', theme: 'fantasy' },
    // { title: 'wireframe', theme: 'wireframe' },
    // { title: 'black', theme: 'black' },
    { title: 'dracula', theme: 'dracula' },
    // { title: 'cmyk', theme: 'cmyk' },
    { title: 'autumn', theme: 'autumn' }, //5
    // { title: 'business', theme: 'business' }, 
    { title: 'night', theme: 'night' },
    { title: 'dim', theme: 'dim' }, //5
    { title: 'nord', theme: 'nord' }, //5
    { title: 'sunset', theme: 'sunset' },
    // { title: 'Cyberpunk', theme: 'cyberpunk' },
    // { title: 'Lemonade', theme:'lemonade'},
    // { title: 'Acid', theme:'acid'},
    // { title: 'Coffee', theme:'coffee'},
    
  ])

  // const theme = useTheme()

  // function themeClick (themeName) {
  //   theme.global.name.value = themeName
  // }

  const daisyClick = (themeName) => {
    useUserDataStore().userData.theme = themeName
  }
  // const vcolors = theme.global.current.value.colors
  // console.log(Object.keys(vcolors))
    
</script>
