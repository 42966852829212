<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <ul class="menu text-center min-h-full bg-base-100 text-base-content">
    <br>
    <h1>My Wishes</h1>
    <br>

    <!-- v for  -->
    <template v-if="!wishes || wishes.length===0">
      <p class="">
        Your wish list is empty.
      </p>
      <br>
      <!-- <button
        class="btn bg-secondary text-secondary-content glass"
      >
        <NuxtLink to="/">
          Explore
        </NuxtLink>
      </button> -->
    <!-- </v-hover> -->
    </template>

    <!-- v else -->
    <div
      v-else
      class=""
    >
      <template
        v-for="(item, idx) in wishes"
        :key="idx"
      >
        <v-icon
          icon="mdi-heart"
          color="secondary"
          size="large"
          class="float-right mb-n16 mr-2 mt-2"
          @click="removeWish(item.id)"
        />
        <NuxtLink :to="'/shop/product/'+item.id">
          <NuxtPicture
            loading="lazy"
            fit="contain"
            quality="80"
            :src="item.photoURL"
            :alt="'I see you are wishing for a ' + item.productTitle + '.  Keep the faith, wishes do come true!'"
            height="250px"
            width="250px"
            :placeholder="[250,250,50,5]"
            class="mt-n6"
          />
        </NuxtLink>
        <NuxtLink
          :to="'/shop/product/'+item.id"
          class="flex flex-col justify-center"
        >
          <v-card-title class="text-wrap text-center">
            {{ item.title }}
          </v-card-title>
          <!-- rounded-0 text-center text-wrap mx-5  -->
          <button
            v-if="item.id"
            class="btn btn-sm mx-auto btn-secondary text-secondary-content"
          >
            View Product
          <!-- {{ variant.cartQty>0 ? `${variant.cartQty} In Cart`: 'Add To Cart' }} -->
          </button>
        </NuxtLink>
        <div class="divider" />
      </template>
    </div>
  </ul>
</template>
<script setup>
import { useUserDataStore } from '~/stores/userData';
import { filter } from 'lodash-es'
const wishes = ref()

onMounted(()=>{
  wishes.value = useUserDataStore().userData.wishes
})

watch(useUserDataStore(),(userStore)=>{
  wishes.value = userStore.userData.wishes
})


const removeWish = (itemId) => {
  if(useUserDataStore().userData.wishes){
    useUserDataStore().userData.wishes = filter(useUserDataStore().userData.wishes,(w)=>{return w.id!=itemId})
  }
}

</script>

<style scoped>

.btnstyle_outlinetofilled{
  transition: .2s ease-in-out 0s;
  color: rgb(var(--v-theme-primary-darken-3));
  border: 3px solid rgb(var(--v-theme-primary-darken-2));
}

.btnstyle_outlinetofilled:hover{
  background-color: rgb(var(--v-theme-primary-darken-3));
  border: 3px solid rgb(var(--v-theme-primary-darken-3));
  color: #fff;
}

.btnstyle_outlinetofilled:disabled{
  background-color: rgb(var(--v-theme-surface-darken-3));
  border: 3px solid rgb(var(--v-theme-surface-darken-3));
  color: #fff;
}

</style>